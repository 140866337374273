<template>
  <div>
    <app-customizer
      slot="customizer"
    />
  </div>
</template>

<script>
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'

export default {
  name: 'LookAndFeel',
  components: {
    AppCustomizer,
  },
}
</script>
